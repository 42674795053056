import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {

  connect() {
    const numImages = this.imageSliderEl().getElementsByClassName('splide__slide').length;
    const arrowsEnabled = numImages > 1;
    const dragable = numImages > 1;
    const thumbnailsCentered = numImages > 3;
    // 9 / 16 = 0.5625
    // width needs to be 99% because the next image shows through otherwise...
    this.imageSlider = new Splide(this.imageSliderEl(), {arrows: arrowsEnabled, heightRatio: 0.5625, width: '98%', cover: true, pagination: false, drag: dragable, rewind: true});
    this.thumbnailSlider = null;
    if(this.thumbnailSliderEl() !== null) {
      this.thumbnailSlider = new Splide(this.thumbnailSliderEl(), {arrows: false, pagination: false, width: '98%', 
                                                              cover: true, heightRatio: 0.5625 / 3, updateOnMove: true,
                                                              perPage: 3, gap: 10, focus: thumbnailsCentered ? 'center' : 0, isNavigation: true}).mount();
      this.imageSlider.sync(this.thumbnailSlider);
    }
    this.imageSlider.mount();
    this.element.addEventListener("click", () => {
      this.showBigSlider();
    });

    this.closingTagEl().addEventListener("click", (event) => {
      event.stopPropagation();
      this.closeBigSlider();
    });
  
  }

  thumbnailSliderEl() {
    const allThumbnailSliders = this.element.getElementsByClassName('thumbnail-slider');
    if (allThumbnailSliders.length !== 1) {
      return null;
    }
    return allThumbnailSliders[0];
  }

  imageSliderEl() {
    return this.element.getElementsByClassName('main-slider')[0];
  }

  closingTagEl() {
    return this.element.getElementsByClassName("invisible-closing-tag")[0];
  }

  showBigSlider() {
    this.element.classList.add("big-image-slider");
    this.closingTagEl().classList.add("visible");

    this.imageSlider.emit('resize');
    this.thumbnailSlider.emit('resize');
  }

  closeBigSlider() {
    this.element.classList.remove("big-image-slider");
    this.closingTagEl().classList.remove("visible");
    this.element.scrollIntoView();
    this.imageSlider.emit('resize');
    this.thumbnailSlider.emit('resize');
  }

}

