import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["short", "full", 'richText', "moreButton", "lessButton"]

  showMore() {
    this.hide(this.shortTarget);
    this.show(this.fullTarget);
    this.hide(this.moreButtonTarget);
    this.show(this.lessButtonTarget);
    this.toggle(this.richTextTarget, 'line-clamp');
  }

  showLess() {
    this.show(this.shortTarget);
    this.hide(this.fullTarget);
    this.hide(this.lessButtonTarget);
    this.show(this.moreButtonTarget);
    this.toggle(this.richTextTarget, 'line-clamp');
  }

  show(target) {
    target.classList.remove("hide")
  }

  hide(target) {
    target.classList.add("hide")
  }

  toggle(target, class_name) {
    target.classList.toggle(class_name)
  }
}
