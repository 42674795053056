import { Controller } from "stimulus"

// Takes a border color and sets it as the background color of an element
// The element foreground color gets changed to something legible, white/black 
export default class extends Controller {

  connect() {
    // We can not use border-color since it is normalized to border-top, border-bottom etc.
    // Use border-bottom as a standin for the entire border-color
    let colorAttribute = window.getComputedStyle(this.element).getPropertyValue('border-bottom-color');
    let colorMatch = colorAttribute.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
    
    if(colorMatch === null) {
      // has no valid border color do nothing
      return;
    }

    //parse colors, array conatins r g b in  order
    let parsedColors = [parseInt(colorMatch[1]), parseInt(colorMatch[2]), parseInt(colorMatch[3])];
    let containsNan = parsedColors.some((color) => {
      return Number.isNaN(color);
    });

    if(containsNan) {
      // has no valid border color do nothing
      return;
    }

    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    // normalize colors

    let normalizedColors = parsedColors.map((color) => color / 255);
    normalizedColors = normalizedColors.map((color) => {
      if(color <= 0.03928) {
        return color / 12.92;
      } else {
        return Math.pow((color + 0.055) / 1.055, 2.4);
      }
    })

    let l = 0.2126 * normalizedColors[0] + 0.7152 * normalizedColors[1] + 0.0722 * normalizedColors[2];
    
    console.log(l);

    // Remove border
    this.element.style.borderStyle = "none";
    // Color in background
    this.element.style.backgroundColor = colorAttribute;
    if(l >= 0.5) {
      // background bright, text dark
      this.element.style.color = "var(--eerie-black)";
    } else {
      this.element.style.color = "var(--white)";
    }

  }

}
