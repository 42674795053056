import { Controller } from "stimulus"
import Cropper from "cropperjs"

export default class extends Controller {
  static targets = ["img", "input", "button", "mainDiv", "imgWrapper"]
  static values = { fileUrl: String }

  connect() {
    let wrapper = document.createElement("div")
    wrapper.innerHTML = this.element.innerHTML
    this.element.innerHTML = ""
    this.element.appendChild(wrapper)

    let newWrapper = document.createElement("div")
    newWrapper.style = "margin-left: 20%; margin-right: 5%; width: 75%;"
    newWrapper.dataset.cropperTarget = "mainDiv"
    if (this.fileUrlValue === "")
      newWrapper.classList.add("d-none")
    let cropBtn = document.createElement("button")
    cropBtn.dataset.cropperTarget = "button"
    cropBtn.dataset.action = "cropper#cropImage"
    cropBtn.innerText = "Crop"
    let img = document.createElement("img")
    img.src = this.fileUrlValue
    img.style = "max-width: 60%"
    img.dataset.cropperTarget = "img"
    newWrapper.appendChild(cropBtn)
    newWrapper.appendChild(img)
    this.element.appendChild(newWrapper)
  }

  imageSelected() {
    let oFReader = new FileReader();
    oFReader.readAsDataURL(this.inputTarget.files[0]);
    oFReader.onload = this.onFileLoad.bind(this)
  }

  onFileLoad(oFREvent) {
    if (!this.hasImgTarget) {
      this.imgWrapperTarget.innerHTML = "<img style='max-width: 100%' data-cropper-target='img'>"
    }
    this.imgTarget.src = oFREvent.target.result;
    this.mainDivTarget.classList.remove("d-none")
  }

  initCrop() {
    const that = this
    this.cropper = new Cropper(this.imgTarget, {
      aspectRatio: 16 / 9,
      crop(event) {
        that.crop(event)
      }
    })
  }

  crop(event) {
    // console.log(event.detail.x);
    // console.log(event.detail.y);
    // console.log(event.detail.width);
    // console.log(event.detail.height);
    // console.log(event.detail.rotate);
    // console.log(event.detail.scaleX);
    // console.log(event.detail.scaleY);
  }

  cropImage(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.cropper) {
      this.cropper.getCroppedCanvas().toBlob(this.cropperCallback.bind(this))
    } else {
      this.initCrop()
    }

  }

  cropperCallback(blob) {
    let file = new File([blob], "img.jpg", { type: blob.type, lastModified: new Date().getTime() })
    let container = new DataTransfer()
    container.items.add(file)
    // fileInputElement.files = container.files;
    this.inputTarget.files = container.files
    this.cropper.destroy()
    this.cropper = null
    this.imageSelected()
  }
}
